import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { IframeApp } from '../../shared/types';
import IframeResizer, { IFrameObject } from 'iframe-resizer-react';
import useStateContext from '../../hooks/useStateContext';
import getAppParams from '../../utils/getAppParams';
import useWidgetContext from '../../hooks/useWidgetContext';
import useFrameSizeSelector from '../../hooks/useFrameSizeSelector';
import formatContextForContentWindow from '../../utils/formatContextForContentWindow';
import { useSearchParams } from 'react-router-dom';

export type Props = {
  dataTestId?: string;
  app: IframeApp;
  minHeight: number;
};

export const APP_FRAME_ID = 'app_frame';

const FrameFacade: React.FC<Props> = ({ app, minHeight }) => {
  const { loading, locale } = useStateContext();
  const [searchParams] = useSearchParams();
  const context = useWidgetContext();
  const currentBp = useFrameSizeSelector();
  const queryParams = searchParams.get('queryParams');
  const iframeRef = useRef<IFrameObject | null>(null);

  const appParams = getAppParams(
    {
      locale: locale.value,
      hostPageParams: context.hostPageParams,
      // If query params isn't a string we cannot join them easily so we'll just skip them
      queryParams: typeof queryParams === 'string' ? queryParams : undefined,
    },
    app,
  );
  const bodyMargin = app.styleParams
    ? (app.styleParams[currentBp]?.bodyMargin ?? null)
    : null;
  const bodyPadding = app.styleParams
    ? (app.styleParams[currentBp]?.bodyPadding ?? null)
    : null;

  // When the partner uses query params we need to append to these params and not create invalid params with multiple `?`
  const frameSource = app.appUrl.includes('?')
    ? `${app.appUrl}&${appParams}`
    : `${app.appUrl}?${appParams}`;

  useEffect(() => {
    if (iframeRef.current !== null && 'sendMessage' in iframeRef.current) {
      const iframeElement = document.getElementById(
        APP_FRAME_ID,
      ) as HTMLIFrameElement | null;
      if (
        iframeElement !== null &&
        iframeElement.contentWindow &&
        loading.value === false
      ) {
        const emitContext = formatContextForContentWindow(context);

        iframeElement.contentWindow?.postMessage(
          JSON.stringify({
            type: 'emitWidgetContext',
            payload: emitContext,
          }),
          '*',
        );

        iframeElement.contentWindow?.postMessage(
          JSON.stringify({
            type: 'receiveConsent',
            payload: {
              ...context.consentParams,
            },
          }),
          '*',
        );
      }
    }
  }, [loading.value, context]);

  /**
   * Here we return a basic iFrame without iFrame resizer
   */
  if (app.bypassResizer === true) {
    return (
      <BasicFrame
        id={APP_FRAME_ID}
        src={frameSource}
        onLoad={() => {
          loading.setValue(false);
        }}
        style={{
          minHeight: minHeight + 'px',
        }}
        loading="lazy"
      />
    );
  }

  return (
    <Frame
      forwardRef={iframeRef}
      id={APP_FRAME_ID}
      inPageLinks
      checkOrigin={false}
      heightCalculationMethod="bodyOffset"
      resizeFrom="child"
      src={frameSource}
      sizeWidth={false}
      bodyMargin={bodyMargin}
      bodyPadding={bodyPadding}
      onLoad={() => {
        loading.setValue(false);
      }}
      style={{
        minHeight: minHeight + 'px',
      }}
      loading="lazy"
    />
  );
};

const Frame = styled(IframeResizer)(
  () => css`
    height: 100%;
    border: none;
    width: 100%;

    transition: opacity 0.3s ease-out;
  `,
);

const BasicFrame = styled.iframe(
  () => css`
    /* Without subtracting 3px we render a scroll bar. Since we're assuming this type of app
     * will deal with their own scroll bar we need to disable this all together.
     */
    height: calc(100% - 3px);
    border: none;
    width: 100%;

    transition: opacity 0.3s ease-out;
  `,
);

export default FrameFacade;
