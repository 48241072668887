import { ROOT } from '../../constants/paths';

/**
 *
 * @param pathname - full pathname may include query parameters but not domain or protocol
 * @returns
 */
const cleanPath = (pathname: string): string => {
  const _pathname = pathname.split('?')[0];

  const cleanPath = _pathname.replace(/^\/\-\/[^/]+\/(iframe|s)/, '').trim();

  const guardedPath = cleanPath === '' ? ROOT : cleanPath;

  return guardedPath;
};

export default cleanPath;
