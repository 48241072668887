import qs from 'query-string';
import { pick } from 'ramda';
import { IframeApp, Locale } from '../../shared/types';

export type StateParams = {
  locale: Locale;
  hostPageParams?: string;
  queryParams?: string;
};

// https://maxchadwick.xyz/tracking-query-params-registry/
const TRACKING_PARAMS = [
  '_ga',
  'adgroupid',
  'campaignid',
  'creative',
  'device',
  'fbclid',
  'feeditemid',
  'gclid',
  'gclsrc',
  'matchtype',
  'placement',
  'utm_campaign',
  'utm_content',
  'utm_id',
  'utm_medium',
  'utm_source',
  'utm_term',
];

const getAppParams = (stateParams: StateParams, app: IframeApp): string => {
  const parsedQueryParams = qs.parse(stateParams.queryParams ?? '');
  const appParams = app.appParams;

  // ARX wants us to add this parameter
  // It changes their behaviour from directly creating the contract to requesting an offer first.
  // This is the desired behaviour by them
  if (app.slug === 'arx-groep') {
    // @ts-ignore
    appParams.offerfirst = 1;
  }
  const formattedStateParams = formatStateParamsForApp(stateParams, app);
  const hostPageParams = qs.parse(stateParams.hostPageParams ?? '');

  return qs.stringify({
    ...pick(TRACKING_PARAMS, hostPageParams),
    ...appParams,
    ...formattedStateParams,
    ...parsedQueryParams,
  });
};

const formatStateParamsForApp = (
  stateParams: StateParams,
  app: IframeApp,
): object => {
  switch (app.slug) {
    case 'enven-energy-energyLabel':
    case 'enven-energy-nen2580':
    case 'enven-energy-sustainabilityAdvice':
    case 'enven-energy-wwsScoringIndependent':
    case 'Homekeur-energy-bouwkundige-keuring':
    case 'Homekeur-energy-energyLabel':
    case 'm-wc':
    case 'vbo-wc':
    case 'bb-wc':
    case 'sus-report': {
      if (app.supportedLang && app.supportedLang.includes(stateParams.locale)) {
        return {
          initialState: JSON.stringify({
            language: stateParams.locale.toUpperCase(),
            // @ts-ignore
            ...(app.appParams.initialState ?? {}),
          }),
        };
      }

      return {};
    }

    default: {
      if (app.supportedLang && app.supportedLang.includes(stateParams.locale)) {
        return {
          locale: stateParams.locale,
        };
      }

      return {};
    }
  }
};

export default getAppParams;
