import { or } from 'ramda';
import React, { forwardRef, HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { SystemSize } from '../../theme/System/Default';
import arrayToCss from '../../utils/arrayToCss';

export type Props = HTMLAttributes<HTMLDivElement> & {
  dataTestId?: string;
  justification?: Justification;
  align?: Justification;
  gap?: SystemSize;
  margin?: Array<SystemSize | null>;
  padding?: Array<SystemSize | null>;
  direction?: 'row' | 'column';
  className?: string;
  nonResponsive?: boolean;
  ref?: React.Ref<HTMLDivElement>;
};

const JustificationContainer = forwardRef<HTMLDivElement, Props>(
  (
    {
      dataTestId,
      children,
      justification = 'start',
      align = 'start',
      margin = [],
      padding = [],
      nonResponsive = false,
      direction = 'row',
      gap,
      ...rest
    },
    ref,
  ) => (
    <Container
      data-testid={dataTestId}
      $justification={justification}
      $align={align}
      $gap={gap}
      $margin={margin}
      $padding={padding}
      $nonResponsive={nonResponsive}
      $direction={direction}
      ref={ref}
      {...rest}
    >
      {children}
    </Container>
  ),
);

type Justification = 'start' | 'end' | 'center' | 'space-between';
type FlexName = 'flex-start' | 'flex-end' | 'center' | 'space-between';

type StartOrEnd = 'start' | 'end';

const isStart = (justification: Justification): justification is 'start' =>
  justification === 'start';
const isEnd = (justification: Justification): justification is 'end' =>
  justification === 'end';
const isStartOrEnd = (
  justification: Justification,
): justification is StartOrEnd =>
  or(isStart(justification), isEnd(justification));

const justificationToFlexName = (direction: Justification): FlexName => {
  if (isStartOrEnd(direction)) return `flex-${direction}` as FlexName;

  return direction;
};

type ContainerProps = {
  $justification: Justification;
  $align: Justification;
  $gap?: Props['gap'];
  $padding?: Props['padding'];
  $margin?: Props['margin'];
  $nonResponsive: boolean;
  $direction: Props['direction'];
  className?: string;
};

const Container = styled.div<ContainerProps>(
  ({
    $justification,
    $align,
    $gap,
    $padding = [null, null, null, null],
    $margin = [null, null, null, null],
    $nonResponsive,
    $direction = 'row',
    theme,
  }) => css`
    display: flex;
    justify-content: ${justificationToFlexName($justification)};
    align-items: ${justificationToFlexName($align)};
    flex-direction: ${$direction};
    ${$gap ? `gap: ${theme.space($gap)};` : ''}

    padding: ${arrayToCss($padding, theme)};
    margin: ${arrayToCss($margin, theme)};

    ${$nonResponsive === false
      ? theme.mq.lessThan('mobile')`
      flex-direction: ${$direction};
    `
      : ''}
  `,
);

export default JustificationContainer;
