import React from 'react';
import { createBrowserRouter, type RouteObject } from 'react-router-dom';
import IframeLayout from './components/iFrameLayout';
import StandaloneLayout from './components/StandaloneLayout';
import IframeLanding from './pages/IframeLanding';
import WithConfig from './components/WithConfig';
import AppPage from './pages/AppPage';
import StandaloneLanding from './pages/StandaloneLanding';

const commonRoutes: Array<RouteObject> = [
  {
    path: 'apps/:slug',
    element: <AppPage />,
  },
];

const router = createBrowserRouter([
  {
    element: <WithConfig />,
    path: '/-',
    children: [
      {
        path: '',
        children: [
          {
            path: ':accountId',
            children: [
              {
                path: 'iframe',
                element: <IframeLayout />,
                children: [
                  {
                    path: '',
                    element: <IframeLanding />,
                  },
                  ...commonRoutes,
                ],
              },
              {
                path: 's',
                element: <StandaloneLayout />,
                children: [
                  {
                    path: '',
                    element: <StandaloneLanding />,
                  },
                  ...commonRoutes,
                ],
              },
            ],
          },
        ],
      },
    ],
  },
]);

export default router;
