import ReactGA from 'react-ga4';
import { isNil } from 'ramda';
import { TrackingInterface } from '../..';
import isAppPath from '../../../../utils/isAppPath';
import getEventName from '../../utils/getEventName';
import getEventNameApp from '../../utils/getEventNameForApp';

const CHAR_LIMIT = 24;

const GoogleTracking: TrackingInterface = {
  onPageView: ({ event, apps, pathname, defaultEventOptions }) => {
    const app = isAppPath(event.path)
      ? apps[event.path.replace(/^\/apps\//, '')]
      : null;

    if (!isNil(app)) {
      return ReactGA.gtag('event', 'page_view', {
        page_title: app.title.nl,
        page_location: pathname,
        page_path: event.path,
        ...defaultEventOptions,
      });
    }

    return ReactGA.gtag('event', 'page_view', {
      page_title: document.title,
      page_location: pathname,
      page_path: event.path,
      ...defaultEventOptions,
    });
  },
  onCollapseWidget: ({ pathname, defaultEventOptions }) =>
    ReactGA.gtag('event', getEventName('e_collapse_wdgt'), {
      event_category: 'interaction',
      event_label: 'Collapse Widget',
      path: pathname,
      ...defaultEventOptions,
    }),
  onExpandWidget: ({ event, defaultEventOptions }) =>
    ReactGA.gtag('event', getEventName('e_expand_wdgt'), {
      event_category: 'interaction',
      event_label: 'Expand Widget',
      path: event.path,
      trigger: event.triggerType,
      ...defaultEventOptions,
    }),
  onDeepLink: ({ event, defaultEventOptions }) =>
    ReactGA.gtag('event', getEventName('e_deep_link'), {
      event_category: 'interaction',
      event_label: 'Expand Widget',
      path: event.path,
      trigger: event.triggerType,
      ...defaultEventOptions,
    }),
  onOpenByCommand: ({ event, defaultEventOptions }) =>
    ReactGA.gtag('event', getEventName('e_open_cmd'), {
      event_category: 'interaction',
      event_label: 'Open Command',
      path: event.path,
      trigger: event.triggerType,
      ...defaultEventOptions,
    }),
  onDismissWidget: ({ pathname, defaultEventOptions }) =>
    ReactGA.gtag('event', getEventName('e_dismiss_wdgt'), {
      event_category: 'interaction',
      event_label: 'Dismiss Widget',
      path: pathname,
      ...defaultEventOptions,
    }),
  onAppsOpened: ({ event, pathname, defaultEventOptions }) =>
    ReactGA.gtag('event', getEventName(`e_opened_${event.amount}_apps`), {
      event_category: 'interaction',
      event_label: `${event.amount} apps opened`,
      path: pathname,
      ...defaultEventOptions,
    }),
  onSessionStart: ({ event, defaultEventOptions }) =>
    ReactGA.gtag('event', getEventName('session_start'), {
      event_category: 'internal',
      event_label: `session start`,
      sessionId: event.sessionId,
      ...defaultEventOptions,
    }),

  onTrackMessage: ({ event, currentApp, defaultEventOptions }) => {
    if (!currentApp) return;

    const postFix =
      event.message.payload.params.conversion === true ? '_conv' : '';

    const prefixedName = getEventNameApp(
      `${currentApp.analyticsPrefix}_${event.message.payload.name.substring(
        0,
        CHAR_LIMIT,
      )}${postFix}`,
    );

    ReactGA.gtag('event', prefixedName, {
      ...event.message.payload.params,
      ...defaultEventOptions,
    });
  },
};

export default GoogleTracking;
