// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/Metropolis/WOFF/Metropolis-Regular.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./fonts/Metropolis/WOFF2/Metropolis-Regular.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./fonts/Metropolis/WOFF/Metropolis-SemiBold.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./fonts/Metropolis/WOFF2/Metropolis-SemiBold.woff2", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*,
*:before,
*:after {
  box-sizing: border-box;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Metropolis';
}

a {
  color: inherit;
  text-decoration: none;
}

@font-face {
  font-family: 'Metropolis';
  src:
    url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('woff'),
    url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('woff2');
  font-weight: 400;
}

@font-face {
  font-family: 'Metropolis';
  src:
    url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('woff'),
    url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format('woff2');
  font-weight: 600;
}
`, "",{"version":3,"sources":["webpack://./styles/globals.css"],"names":[],"mappings":"AAAA;;;EAGE,sBAAsB;AACxB;;AAEA;;EAEE,UAAU;EACV,SAAS;EACT,yBAAyB;AAC3B;;AAEA;EACE,cAAc;EACd,qBAAqB;AACvB;;AAEA;EACE,yBAAyB;EACzB;;2DAE0E;EAC1E,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB;;2DAE2E;EAC3E,gBAAgB;AAClB","sourcesContent":["*,\n*:before,\n*:after {\n  box-sizing: border-box;\n}\n\nhtml,\nbody {\n  padding: 0;\n  margin: 0;\n  font-family: 'Metropolis';\n}\n\na {\n  color: inherit;\n  text-decoration: none;\n}\n\n@font-face {\n  font-family: 'Metropolis';\n  src:\n    url('./fonts/Metropolis/WOFF/Metropolis-Regular.woff') format('woff'),\n    url('./fonts/Metropolis/WOFF2/Metropolis-Regular.woff2') format('woff2');\n  font-weight: 400;\n}\n\n@font-face {\n  font-family: 'Metropolis';\n  src:\n    url('./fonts/Metropolis/WOFF/Metropolis-SemiBold.woff') format('woff'),\n    url('./fonts/Metropolis/WOFF2/Metropolis-SemiBold.woff2') format('woff2');\n  font-weight: 600;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
