import React from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Heading4, Heading5 } from '../Typography';
import JustificationContainer from '../JustificationContainer';
import ProgressiveImgIx from '../ProgressiveImgIx';

export type Props = {
  dataTestId?: string;
  title: React.ReactNode;
  description: React.ReactNode;
  icon: { src: string };

  /*
   * What page should be loaded next? e.g. /apps/bb-wc
   */
  next: string;

  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
};

const ListItem: React.FC<Props> = ({
  dataTestId,
  title,
  icon,
  description,
  next,
  onClick,
  ...rest
}) => (
  <Link to={next} onClick={onClick}>
    <Container data-testid={dataTestId} {...rest} padding={['s']}>
      <IconWrapper align="center" justification="center">
        <ProgressiveImgIx width={ILLUSTRATION_SIZE} src={icon.src} />
      </IconWrapper>
      <JustificationContainer direction="column">
        <Title>{title}</Title>
        <Description>{description}</Description>
      </JustificationContainer>
    </Container>
  </Link>
);

const Container = styled(JustificationContainer)<{}>(
  ({ theme }) => css`
    border-radius: ${theme.getSystem().border.radius.base};
    border: 1px solid transparent;
    transition: all 0.2s ease-out;
    cursor: pointer;

    &:hover {
      border: 1px solid #dadfe6;
      box-shadow: ${theme.boxShadow('s')};

      ${Title} {
        color: ${theme.color('secondary')};
      }
    }
  `,
);

const Description = styled(Heading5)<{}>`
  color: #4a4a4a;
`;

const Title = styled(Heading4)<{}>(
  ({ theme }) => css`
    font-weight: ${theme.fw('semiBold')};
    color: ${theme.color('primary')};
  `,
);

const ILLUSTRATION_SIZE = 80;
const IconWrapper = styled(JustificationContainer)<{}>(
  ({ theme }) => css`
    width: 100%;
    height: ${ILLUSTRATION_SIZE}px;
    max-width: ${ILLUSTRATION_SIZE}px;
    flex-shrink: 0;
    background: #f7f7f7;
    border-radius: ${theme.getSystem().border.radius.base};
    padding: ${theme.space('xxs')};
    margin-right: ${theme.space('base')};

    & img {
      max-height: calc(${ILLUSTRATION_SIZE}px - ${theme.space('xxs')});
    }
  `,
);

export default ListItem;
