import { useCallback, useEffect, useState } from 'react';
import useWidgetContext from '../useWidgetContext';
import useMessaging from '../useMessaging';
import shouldDismiss from '../../utils/shouldDismiss';
import useFireTrackingEvent from '../useFireTrackingEvent';

type OnDismiss = () => void;
type OnClearDismiss = () => void;
type isDismissed = boolean;

const useDismiss = (): [isDismissed, OnDismiss, OnClearDismiss] => {
  const fireTrackingEvent = useFireTrackingEvent();
  const { accountId } = useWidgetContext();

  const DISMISS_KEY = `DH_UW_DISMISSED_${accountId}`;

  const [dismissed, setDismissed] = useState<boolean>(
    shouldDismiss(getItem(DISMISS_KEY)),
  );

  const sendMessage = useMessaging(message => {
    if (message.type === 'clearDismiss') {
      return onClearDismiss();
    }
    if (message.type === 'dismiss') {
      return onDismiss(true);
    }
  });

  const onClearDismiss = useCallback(() => {
    removeItem(DISMISS_KEY);
    setDismissed(false);
    sendMessage({ type: 'clearDismiss', payload: {} });
  }, [DISMISS_KEY, sendMessage]);

  const onDismiss = useCallback(
    (fromCmd: boolean = false) => {
      setItem(DISMISS_KEY, `${Date.now()}`);
      setDismissed(true);

      if (fromCmd === false) {
        sendMessage({ type: 'dismiss', payload: {} });
      }

      fireTrackingEvent({
        event: 'DismissWidget',
      });
    },
    [DISMISS_KEY, sendMessage, fireTrackingEvent],
  );

  useEffect(() => {
    const dismissTime = getItem(DISMISS_KEY);
    if (!dismissTime) return;

    if (shouldDismiss(dismissTime)) {
      setDismissed(true);
      sendMessage({ type: 'dismiss', payload: {} });
    } else {
      onClearDismiss();
    }
  }, []);

  return [dismissed, onDismiss, onClearDismiss];
};

const getItem = (item: string): string | null => {
  try {
    return localStorage.getItem(item);
  } catch {
    return null;
  }
};

const setItem = (item: string, value: string) => {
  try {
    localStorage.setItem(item, value);
  } catch {}
};

const removeItem = (item: string) => {
  try {
    localStorage.removeItem(item);
  } catch {}
};

export default useDismiss;
