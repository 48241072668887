import React from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import HighlightedComponent from '~/components/HighlightedComponent';
import ServiceList from '~/components/ServiceList';
import useCopy from '~/hooks/useCopy';
import useLocale from '~/hooks/useLocale';
import usePathPrefix from '~/hooks/usePathPrefix';
import useWidgetContext from '~/hooks/useWidgetContext';

const IframeLanding: React.FC = ({}) => {
  const context = useWidgetContext();
  const pathPrefix = usePathPrefix({ variant: 'iframe' });
  const { serviceList } = useCopy();
  const locale = useLocale();
  const { highlighted } = context;

  return (
    <Main>
      <Helmet>
        <title>App Overzicht</title>
      </Helmet>
      <HighlightedComponent
        title={highlighted.title[locale]}
        description={highlighted.description[locale]}
        next={`${pathPrefix}/apps/${highlighted.slug}`}
        imageUrl={highlighted.image?.src}
      />
      <ServiceList
        items={context.apps}
        header={serviceList.apps}
        basePath={`${pathPrefix}/apps`}
        dataTestId="landing-service-list"
        includeHighlighted={false}
      />
      {/* Here's the place for the CTA component when we know what should be in there */}
    </Main>
  );
};

const Main = styled.main``;

export default IframeLanding;
