import { useEffect, useRef } from 'react';
import ReactGA from 'react-ga4';
import { IWidgetContext } from '../../context/WidgetContext';
import { isNil } from 'ramda';

import useDHFlags from '../useDHFlags';
import useWidgetContext from '../useWidgetContext';
import serializeConsentOptions from '../../utils/serializeConsentOptions';

// https://www.simoahava.com/analytics/cookieflags-field-google-analytics/
const COOKIE_FLAGS = 'max-age=7200;secure;samesite=none';

const VERSION = 'v1';

export const useDefaultEventOptions = () => {
  const flags = useDHFlags();
  const widgetContext = useWidgetContext();

  const anyFlagPotential = widgetContext.featureFlags.length !== 0;

  const version = anyFlagPotential
    ? flags.length !== 0
      ? `v3/${flags.map(({ name }) => name).join('+')}`
      : 'v3'
    : VERSION;

  const config = {
    cookie_flags: COOKIE_FLAGS,
    anonymize_ip: true,
    send_page_view: false,
    accountId: widgetContext.accountId,
  };

  return {
    ...config,
    version,
  };
};

const useSetupAnalytics = (widgetContext: IWidgetContext | null) => {
  const defaultConfigOptions = useDefaultEventOptions();
  const _gtag = useRef<typeof ReactGA.gtag | null>(null);

  useEffect(() => {
    if (widgetContext === null || ReactGA.isInitialized === true) return;

    window.dataLayer.push({
      event: 'gtm.init_consent',
      ad_storage: 'denied',
      analytics_storage: 'denied',
      functionality_storage: 'denied',
      personalization_storage: 'denied',
    });

    const trackingIds = [, process.env.NEXT_PUBLIC_GA_ID].filter(
      trackingId => !isNil(trackingId),
    );

    ReactGA.initialize(
      trackingIds.map(trackingId => ({
        trackingId,
        gtagOptions: defaultConfigOptions,
      })),
    );

    _gtag.current = ReactGA.gtag;

    // Set default consent options before config is initialized
    ReactGA.gtag(
      'consent',
      'default',
      {
        ad_storage: 'denied',
        analytics_storage: 'denied',
        functionality_storage: 'denied',
        personalization_storage: 'denied',
      },
      '${process.env.NEXT_PUBLIC_GA_ID}',
    );

    if (widgetContext.googleAnalytics4) {
      ReactGA.gtag(
        'consent',
        'default',
        {
          ad_storage: 'denied',
          analytics_storage: 'denied',
          functionality_storage: 'denied',
          personalization_storage: 'denied',
        },
        widgetContext.googleAnalytics4,
      );
    }
  }, [defaultConfigOptions, widgetContext]);

  // Watch for consent updates
  useEffect(() => {
    if (widgetContext === null) return;

    const serializedConsentParams = serializeConsentOptions(
      widgetContext.consentParams,
    );

    // Update consent settings for our GA ID
    ReactGA.gtag(
      'consent',
      'update',
      serializedConsentParams,
      process.env.NEXT_PUBLIC_GA_ID,
    );

    // Update consent settings for the customer' GA4 ID if defined
    if (!isNil(widgetContext.googleAnalytics4)) {
      ReactGA.gtag(
        'consent',
        'update',
        serializedConsentParams,
        widgetContext.googleAnalytics4,
      );
    }

    // Push consent update to the dataLayer for GTM to recognize (for all tags)
    window.dataLayer.push({
      event: 'consent_update',
      ...serializedConsentParams,
    });
  }, [widgetContext?.consentParams]);

  return _gtag.current;
};

// gtag('js', new Date());

// // Set default consent options before config is initialized
//   gtag('consent', 'default', {
//     ad_storage: 'denied',
//     analytics_storage: 'denied',
//     functionality_storage: 'denied',
//     personalization_storage: 'denied',
//   }, '${process.env.NEXT_PUBLIC_GA_ID}');

//   ${
//     ga4Id
//       ? `
//         gtag('consent', 'default', {
//           ad_storage: 'denied',
//           analytics_storage: 'denied',
//           functionality_storage: 'denied',
//           personalization_storage: 'denied',
//           }, '${ga4Id}');
//         `
//       : ''

export default useSetupAnalytics;
