const log = (...args: any) =>
  window.DH_DEBUG === true &&
  // eslint-disable-next-line no-console
  console.log(
    `%c[DatHuis - Umbrella widget]`,
    `color: #00A3C3; font-weight: bold;`,
    ...args,
  );

export default log;
