const createHash = (obj: Record<string, any>): string => {
  const jsonString = JSON.stringify(obj, Object.keys(obj).sort());

  // Fowler–Noll–Vo hash function
  // https://www.wikiwand.com/en/articles/Fowler%E2%80%93Noll%E2%80%93Vo_hash_function
  let hash = 2166136261; // FNV offset basis
  for (let i = 0; i < jsonString.length; i++) {
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Bitwise_XOR_assignment
    hash ^= jsonString.charCodeAt(i);
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Left_shift
    hash +=
      (hash << 1) + (hash << 4) + (hash << 7) + (hash << 8) + (hash << 24);
  }

  // Convert to a short hexadecimal string
  return (hash >>> 0).toString(36);
};

export default createHash;
