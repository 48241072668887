import { IWidgetContext } from '../../context/WidgetContext';
import {
  ContentWindowWidgetContext,
  ContentWindowApp,
} from '../../shared/types';

const formatContextForContentWindow = (
  widgetContext: IWidgetContext,
): ContentWindowWidgetContext => {
  const { apps, accountId } = widgetContext;
  return {
    accountId,
    enabledApps: Object.values(apps).map(
      ({ slug, title }): ContentWindowApp => ({
        slug: `/apps/${slug}`,
        name: title,
      }),
    ),
  };
};

export default formatContextForContentWindow;
