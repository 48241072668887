import { Appearance } from './types';

export type FrameSize = {
  width: string;
  height: string;
};

type FrameSizes = {
  collapsed: {
    [Appearance.Large]: {
      mobile: FrameSize;
      desktop: FrameSize;
    };
    [Appearance.Small]: {
      mobile: FrameSize;
      desktop: FrameSize;
    };
  };
  narrow: {
    mobile: FrameSize;
    desktop: FrameSize;
  };
  wide: {
    mobile: FrameSize;
    desktop: FrameSize;
  };
  fullscreen: {
    mobile: FrameSize;
    desktop: FrameSize;
  };
  hidden: {
    mobile: FrameSize;
    desktop: FrameSize;
  };
};

export const iframeSizes: FrameSizes = {
  collapsed: {
    [Appearance.Large]: {
      mobile: {
        width: '100%',
        height: '210px',
      },
      desktop: {
        width: '320px',
        height: '215px',
      },
    },
    [Appearance.Small]: {
      mobile: {
        width: '154px',
        height: '236px',
      },
      desktop: {
        width: '154px',
        height: '190px',
      },
    },
  },
  narrow: {
    mobile: {
      width: '100%',
      height: '100%',
    },
    desktop: {
      width: '320px',
      height: '95%',
    },
  },
  wide: {
    mobile: {
      width: '100%',
      height: '100%',
    },
    desktop: {
      width: '100%',
      height: '100%',
    },
  },
  fullscreen: {
    mobile: {
      width: '100%',
      height: '100%',
    },
    desktop: {
      width: '100%',
      height: '100%',
    },
  },
  hidden: {
    /**
     * Since we add additional size for the close button and shadow
     * when we set this to 0, we actually end up in a calc(0 + 16px)
     * Because this is now set to -16px it will be calc(-16px + 16px) === 0px
     */
    mobile: {
      width: '-16px',
      height: '-16px',
    },
    desktop: {
      width: '-16px',
      height: '-16px',
    },
  },
} as const;

// 425 is the wides breakpoint that is listed as "Mobile" in Chrome devtools
// everything bigger than 425 is seen as a tablet.
// We will use the "Mobile L" value
export const mobileBreakpoint = 425;

export const DH_PARAMS_QUERY_STRING_KEY = 'dhuwParams';
