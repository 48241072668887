import { LayoutT } from '../../../../shared/types';

type Args = {
  pathname: string;
  isFullScreen?: boolean;
  expanded?: boolean;
};

const getCurrentLayout = ({
  pathname,
  isFullScreen,
  expanded = false,
}: Args): LayoutT => {
  if (expanded === false) return 'collapsed';

  const onAppsPage = pathname.includes('apps');

  return isFullScreen && onAppsPage
    ? 'fullscreen'
    : onAppsPage
      ? 'wide'
      : 'narrow';
};

export default getCurrentLayout;
