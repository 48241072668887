import React from 'react';
import styled, { css } from 'styled-components';
import Icon from '../../../Icon';
import JustificationContainer from '../../../JustificationContainer';
import HeaderContainer from '../HeaderContainer';
import LanguageSwitch from '../LanguageSwitch';

export type Props = {
  dataTestId?: string;
  onClose?: () => void;
};

const Narrow: React.FC<Props> = ({ onClose }) => (
  <HeaderContainer>
    <JustificationContainer justification="end" align="center">
      <LanguageSwitch />
      <StyledIcon
        name="close"
        onClick={onClose}
        strokeWidth={3}
        data-testid="layout-close-button"
      />
    </JustificationContainer>
  </HeaderContainer>
);

const BACKGROUND_SIZE = 32;
const StyledIcon = styled(Icon)<{}>(
  ({ theme }) => css`
    cursor: pointer;
    padding: 8px;
    transition: all 0.3s ease-out;
    border-radius: ${theme.getSystem().border.radius.full};
    width: ${BACKGROUND_SIZE}px;
    height: ${BACKGROUND_SIZE}px;
    display: flex;
    color: #000;
  `,
);
export default Narrow;
