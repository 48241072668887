import React from 'react';
import styled, { css } from 'styled-components';
import { ComponentProps } from '../../../../';
import { ROOT } from '../../../../../../constants/paths';
import useFireTrackingEvent from '../../../../../../hooks/useFireTrackingEvent';
import { iframeSizes } from '../../../../../../shared/constants';
import { Appearance } from '../../../../../../shared/types';
import Icon from '../../../../../Icon';
import JustificationContainer from '../../../../../JustificationContainer';
import { Heading4 } from '../../../../../Typography';
import CloseButton, { CLOSE_BUTTON_SIZE } from './components/CloseButton';
import HighlightedHeader from './components/HighlightedHeader';
import useFrameSizeSelector from '../../../../../../hooks/useFrameSizeSelector';

const Initial: React.FC<Omit<ComponentProps, 'size'>> = ({
  dataTestId,
  onDismiss,
  onTrigger,
  position,
  footer,
  header,
  description,
  illustration,
  headerLink,
  hasOnlyOneApp = false,
  ...rest
}) => {
  const currentBp = useFrameSizeSelector();
  const fireEvent = useFireTrackingEvent();

  return (
    <Container
      {...rest}
      data-testid={dataTestId}
      $currentBp={currentBp}
      $size={Appearance.Large}
    >
      <HighlightedHeader
        onClick={() => {
          onTrigger(headerLink);
          fireEvent({
            event: 'ExpandWidget',
            path: headerLink,
            triggerType: 'headerClick',
          });
        }}
        header={header}
        description={description}
        illustration={illustration}
      />
      <TriggerFooter
        padding={['m']}
        align="start"
        dataTestId="trigger-footer"
        onClick={() => {
          const path = hasOnlyOneApp ? headerLink : ROOT;
          onTrigger(path);
          fireEvent({
            path,
            event: 'ExpandWidget',
            triggerType: 'footerClick',
          });
        }}
      >
        <StyledHeading4 $withoutMargin>{footer}</StyledHeading4>
        <Icon name="arrow-right" />
      </TriggerFooter>
      <CloseButton
        $position={position}
        onClick={e => {
          e.stopPropagation();
          onDismiss();
        }}
      >
        <Icon name="close" />
      </CloseButton>
    </Container>
  );
};

const Container = styled.div<{
  $currentBp: 'desktop' | 'mobile';
  $size: Appearance;
}>(
  ({ theme, $currentBp, $size }) => css`
    position: relative;
    border-radius: ${theme.getSystem().border.radius.base};
    width: ${iframeSizes.collapsed[$size][$currentBp].width};
    max-width: calc(
      ${iframeSizes.collapsed[$size][$currentBp].width} - ${CLOSE_BUTTON_SIZE}px
    );
    box-shadow: ${theme.boxShadow('s')};
    margin: ${CLOSE_BUTTON_SIZE}px ${theme.space('base')} 0
      ${theme.space('base')};
    cursor: pointer;

    display: flex;
    flex-direction: column;
  `,
);

const StyledHeading4 = styled(Heading4)<{}>(
  ({ theme }) => css`
    margin-right: ${theme.space('m')};
  `,
);

const TriggerFooter = styled(JustificationContainer)<{}>(
  ({ theme }) => css`
    background: ${theme.color('primary', 'text')};
    color: ${theme.color('primary')};
    border-radius: 0 0 ${theme.getSystem().border.radius.base}
      ${theme.getSystem().border.radius.base};

    svg {
      height: 1em;
      width: 1em;
    }
  `,
);

export default Initial;
