import React from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import ServiceList from '~/components/ServiceList';
import useCopy from '~/hooks/useCopy';
import usePathPrefix from '~/hooks/usePathPrefix';
import useWidgetContext from '~/hooks/useWidgetContext';

const StandaloneLanding: React.FC = ({}) => {
  const context = useWidgetContext();
  const pathPrefix = usePathPrefix({ variant: 'standalone' });
  const { serviceList } = useCopy();

  return (
    <Main>
      <Helmet>
        <title>App Overzicht</title>
      </Helmet>
      <ServiceList
        items={context.apps}
        header={serviceList.apps}
        basePath={`${pathPrefix}/apps`}
        dataTestId="landing-service-list"
        includeHighlighted={true}
      />
      {/* Here's the place for the CTA component when we know what should be in there */}
    </Main>
  );
};

const Main = styled.main``;

export default StandaloneLanding;
