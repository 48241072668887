import React, { useContext, useEffect, useState } from 'react';
import styled, { css, keyframes, useTheme } from 'styled-components';
import HostInfoContext from '../../context/HostInfoContext';

import useLocale from '../../hooks/useLocale';
import { HostInfo } from '../../shared/types';
import Icon from '../Icon';
import copyTextToClipboard from './utils/copyTextToClipboard';
import useMessaging from '../../hooks/useMessaging';
import { useParams } from 'react-router-dom';
import { isEmpty, isNil } from 'ramda';

const text = {
  tooltip: {
    nl: 'Link naar deze tool kopiëren',
    en: 'Copy link to this tool',
  },
  tooltipCopied: {
    nl: 'Link gekopieërd',
    en: 'Link copied',
  },
};

const CopyDeeplink: React.FC<{}> = () => {
  const theme = useTheme();
  const locale = useLocale();
  const [copied, setCopied] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const hostInfo = useContext(HostInfoContext);
  const { slug } = useParams();

  const sendMessage = useMessaging();

  useEffect(() => {
    sendMessage({
      type: 'getHostInfo',
    });
  }, [sendMessage]);

  useEffect(() => {
    let didCancel = false;
    let timeout: NodeJS.Timeout;
    if (copied === true) {
      timeout = setTimeout(() => {
        if (!didCancel) setCopied(false);
      }, 1000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
      didCancel = true;
    };
  }, [copied]);

  const deepLink = generateDeepLink({ hostInfo, slug });

  if (!deepLink) return null;

  return (
    <Button
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
      onTouchEnd={() => {
        setTimeout(() => {
          setShowTooltip(false);
        }, 1000);
      }}
      onClick={() => {
        copyTextToClipboard(deepLink).then(() => {
          setCopied(true);
        });
      }}
    >
      <Icon
        name={copied ? 'check' : 'copy'}
        color={copied ? theme.color('success') : theme.color('secondary')}
      />
      {showTooltip && (
        <Tooltip>
          {copied ? text.tooltipCopied[locale] : text.tooltip[locale]}
        </Tooltip>
      )}
    </Button>
  );
};

const generateDeepLink = ({
  slug,
  hostInfo,
}: {
  hostInfo?: HostInfo | null;
  slug?: string;
}): string | null => {
  // Not an apps page.
  if (!slug) return null;

  const dhuwParams = !isNil(hostInfo)
    ? {
        path: `/apps/${slug}`,
      }
    : {};

  const _hostInfo: HostInfo = hostInfo ?? global.window.location;

  const pathName = _hostInfo.pathname;

  const path =
    pathName.length > 0 && pathName !== '/'
      ? _hostInfo.origin + pathName
      : _hostInfo.origin;

  if (isEmpty(dhuwParams)) {
    return path;
  }

  return `${path}?dhuwParams=${encodeURIComponent(JSON.stringify(dhuwParams))}`;
};

const BUTTON_SIZE = 32;

const Button = styled.button(
  ({ theme }) => css`
    position: relative;
    width: ${BUTTON_SIZE}px;
    height: ${BUTTON_SIZE}px;
    padding: 8px;
    border-radius: ${theme.getSystem().border.radius.full};
    border: none;
    outline: none;
    background: transparent;
    cursor: pointer;

    transition: all 0.3s ease-out;

    svg {
      color: ${theme.color('secondary')};
    }
  `,
);

const Tooltip = styled.div(
  ({ theme }) => css`
    background-color: ${theme.color('primary')};
    color: ${theme.color('primary', 'text')};
    padding: ${theme.space('s')};
    border-radius: ${theme.getSystem().border.radius.base};
    width: 200px;
    position: absolute;
    top: -160%;
    right: 0;

    animation: ${fadeIn} 0.2s ease-out forwards;

    &:after {
      content: '';
      position: absolute;
      bottom: -1rem;
      right: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 1.5rem 1rem 0 1rem;
      border-color: ${theme.color('primary')} transparent transparent
        transparent;
    }
  `,
);

const fadeIn = keyframes`
  from { opacity: 0; transform: translate(0, 10px); }
  to { opacity: 1; transform: translate(0, 0px); }
`;

export default CopyDeeplink;
