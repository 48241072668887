import React from 'react';
import styled, { css } from 'styled-components';
import illustrations from '../../../../../../../Illustration/components';
import JustificationContainer from '../../../../../../../JustificationContainer';
import { Heading2 } from '../../../../../../../Typography/index';
import Illustration from '../../../../../../../Illustration';

export type Props = {
  dataTestId?: string;
  header: React.ReactNode;
  illustration?: keyof typeof illustrations;
} & React.HtmlHTMLAttributes<HTMLDivElement>;

const ILLUSTRATION_SIZE = 50;
const HighlightedHeader: React.FC<Props> = ({
  dataTestId,
  header,
  illustration,
  ...rest
}) => (
  <Container data-testid={dataTestId} {...rest}>
    <JustificationContainer align="start" direction="column">
      <StyledIllustration name={illustration || 'bb-wc'} />
      <StyledHeading2 $withoutMargin $size="m">
        {header}
      </StyledHeading2>
    </JustificationContainer>
  </Container>
);

const Container = styled.div<{}>(
  ({ theme }) => css`
    background-color: ${theme.color('primary')};
    padding: ${theme.space('base')};
    border-radius: ${theme.getSystem().border.radius.base}
      ${theme.getSystem().border.radius.base} 0 0;
  `,
);

const StyledHeading2 = styled(Heading2)<{}>(
  ({ theme }) => css`
    color: ${theme.color('primary', 'text')};
    font-size: ${theme.fontSize('s')};
    margin-top: ${theme.space('xs')};
  `,
);

const StyledIllustration = styled(Illustration)<{}>(
  ({ theme }) => css`
    svg {
      height: ${ILLUSTRATION_SIZE}px;
      width: ${ILLUSTRATION_SIZE}px;
      color: ${theme.color('primary', 'text')};
    }
  `,
);

export default HighlightedHeader;
