import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';

export type Props = {
  dataTestId?: string;
  lowQualitySrc: string;
  highQualitySrc: string;
  srcset?: string;
  width: number;
};

const ProgressiveImage: React.FC<Props> = ({ srcset, ...props }) => {
  const [source, setSource] = useState<string>(props.lowQualitySrc);
  const [loaded, setLoaded] = useState<boolean>(false);
  const highQualitySrc = props.highQualitySrc ?? props.lowQualitySrc;
  const lowQualitySrc = props.lowQualitySrc ?? props.highQualitySrc;

  useEffect(() => {
    let sourceWasUpdated = false;
    setLoaded(false);
    setSource(lowQualitySrc);
    const img = new Image();
    if (srcset) {
      img.srcset = srcset;
    } else {
      img.src = highQualitySrc;
    }
    img.onload = () => {
      if (!sourceWasUpdated) {
        setSource(highQualitySrc);
        setLoaded(true);
      }
    };
    return () => {
      sourceWasUpdated = true;
    };
  }, [lowQualitySrc, highQualitySrc, srcset]);

  return (
    <LocalImage
      src={source}
      srcSet={loaded ? srcset : undefined}
      blurred={!loaded}
      {...props}
    />
  );
};

const LocalImage = styled.img<{ blurred: boolean; width: number }>(
  ({ blurred, width }) => css`
    ${blurred ? 'filter: blur(20px); transition: filter 1s ease-out;' : ''}
    max-width: ${width}px;
    max-height: ${width}px;
    width: 100%;

    display: block;
    page-break-inside: avoid;
  `,
);

export default ProgressiveImage;
