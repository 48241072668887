import { useParams } from 'react-router-dom';
import useWidgetContext from '../useWidgetContext';

const useCurrentApp = () => {
  const pageParams = useParams();
  const { apps } = useWidgetContext();

  const slug = pageParams.slug;
  if (!slug || typeof slug !== 'string') return null;

  return apps[slug] ?? null;
};

export default useCurrentApp;
