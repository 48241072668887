import { useRef, useCallback, useEffect } from 'react';

/**
 * Returns a memoized function that will only call the passed function when it hasn't been called for the wait period
 * @param callback The function to be called
 * @param time Wait period after function hasn't been called for
 * @returns A memoized function that is debounced
 */
const useDebouncedCallback = <T extends (...args: any[]) => void>(
  callback: T,
  time: number,
) => {
  // Use a ref to store the timeout between renders
  // and prevent changes to it from causing re-renders
  const timeout = useRef<ReturnType<typeof setTimeout>>();

  useEffect(
    // Clear timeout when outside component gets unmounted
    () => () => {
      if (timeout.current) clearTimeout(timeout.current);
    },
    [],
  );

  return useCallback(
    (...args: any) => {
      if (timeout.current) clearTimeout(timeout.current);
      timeout.current = setTimeout(() => {
        callback(...args);
      }, time);
    },
    [callback, time],
  ) as T;
};

export default useDebouncedCallback;
