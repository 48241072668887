import { LayoutT } from '../../../../shared/types';

type Args = {
  pathname: string;
  isFullScreen?: boolean;
  expanded?: boolean;
};

const getCurrentLayout = ({}: Args): LayoutT => 'fullscreen';

export default getCurrentLayout;
