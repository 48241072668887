import {} from 'react';
import useWidgetContext from '../useWidgetContext';
export type LayoutVariant = 'iframe' | 'standalone';

const usePathPrefix = ({ variant }: { variant: LayoutVariant }): string => {
  const context = useWidgetContext();

  switch (variant) {
    case 'standalone':
      return `/-/${context.accountId}/s`;
    default:
      return `/-/${context.accountId}/iframe`;
  }
};

export default usePathPrefix;
