import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { setContext, getCurrentScope } from '@sentry/react';
import styled, { css } from 'styled-components';

import { Position, LayoutT } from '../../shared/types';

import getCurrentLayout from './utils/getCurrentLayout';
import cleanPath from '../../utils/cleanPath';
import { ROOT } from '../../constants/paths';

import Header, { HeaderAction } from '../Header';
import Footer from '../Footer';

import useWidgetContext from '../../hooks/useWidgetContext';
import useCopy from '../../hooks/useCopy';
import usePathPrefix from '../../hooks/usePathPrefix';
import useFireTrackingEvent from '../../hooks/useFireTrackingEvent';
import useFrameSizeSelector from '../../hooks/useFrameSizeSelector';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';

export type Props = {};

const StandaloneLayout: React.FC<Props> = ({ ...rest }) => {
  const location = useLocation();
  const { slug } = useParams();
  const navigate = useNavigate();
  const pathPrefix = usePathPrefix({ variant: 'standalone' });
  const fireTrackingEvent = useFireTrackingEvent();
  const context = useWidgetContext();

  const { positions, apps } = context;
  const [expanded, setExpanded] = useState<boolean>(false);
  const { actions: actionLabels } = useCopy();
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
  const frameSizeSelector = useFrameSizeSelector();

  const containerRef = useRef<HTMLDivElement | null>(null);

  const root = pathPrefix + ROOT;

  const layoutType = getCurrentLayout({
    isFullScreen,
    pathname: location.pathname,
    expanded,
  });

  const hasOnlyOneApp = useMemo(() => Object.keys(apps).length === 1, [apps]);
  const currentApp =
    slug && typeof slug === 'string' ? context.apps[slug] : null;

  useEffect(() => {
    setContext('widget-context', {
      ...context,
    });

    return () => {
      // When we unmount the layout we need to clear the scope
      // https://docs.sentry.io/platforms/javascript/guides/nextjs/enriching-events/context/#clearing-context
      getCurrentScope().clear();
    };
  }, [context]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape' && layoutType === 'fullscreen') {
        setIsFullScreen(false);
      }
    },
    [layoutType],
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  useEffect(() => {
    const cleanedPath = cleanPath(location.pathname);
    fireTrackingEvent({
      event: 'VirtualPageView',
      path: cleanedPath,
    });
  }, [location, fireTrackingEvent]);

  const actions: Array<HeaderAction> = currentApp
    ? [
        {
          label: actionLabels.goBackToOverview,
          icon: 'arrow-left',
          onClick: () => {
            setIsFullScreen(false);

            if (hasOnlyOneApp) {
              setExpanded(false);
            }
            navigate(root);
          },
        },
      ]
    : [];

  const onClose = useCallback(() => {
    setExpanded(false);

    fireTrackingEvent({
      event: 'DismissWidget',
    });

    navigate(root);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  if (context.preventDefaultOpening === true && expanded === false) {
    return null;
  }

  return (
    <Container ref={containerRef}>
      <Overlay
        {...rest}
        layout={layoutType}
        $position={positions[frameSizeSelector]}
      >
        <WidgetContainer layout={layoutType}>
          <Header appearance={layoutType} onClose={onClose} actions={actions} />
          <Inner $bypassResizer={currentApp ? currentApp.bypassResizer : false}>
            <Outlet />
          </Inner>
          <Footer />
        </WidgetContainer>
      </Overlay>
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  height: 100%;
`;

const Overlay = styled.div<{
  layout: LayoutT;
  $position: Position;
}>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-end;
  align-self: flex-start;
`;

export const scrollBarStyles = css`
  &::-webkit-scrollbar {
    width: 0.5em;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.color('secondary')};
    border-radius: ${({ theme }) => theme.getSystem().border.radius.base};
    outline: none;
  }
`;

const WidgetContainer = styled.div<{ layout: LayoutT }>(
  ({ theme }) => css`
    background-color: #fff;
    border-radius: ${theme.getSystem().border.radius.base};
    box-shadow: ${theme.boxShadow('s')};

    position: relative;

    display: flex;
    flex-direction: column;
    height: 100vh;

    /* max-width: 1280px; */
    width: 100%;
    margin: 0 auto;
  `,
);

const Inner = styled.div<{ $bypassResizer?: boolean }>(
  ({ $bypassResizer }) => css`
    height: 100%;
    width: 100%;

    overflow-y: ${$bypassResizer ? 'auto' : 'scroll'};

    ${scrollBarStyles}
  `,
);

export default StandaloneLayout;
