import React from 'react';
import styled, { css } from 'styled-components';
import { SystemSize } from '../../theme/System/Default';

export type Variant = 'primary' | 'secondary';

export type Props<T = React.HTMLAttributes<any>> = T & {
  className?: string;
  as?: $HtmlElementTag;
  $variant?: Variant;
  $size?: SystemSize;
  dataTestId?: string;
  $withoutMargin?: boolean;
  $error?: boolean;
};

const Typography: React.FC<Props> = ({
  dataTestId = '',
  children,
  ...props
}) => (
  <Container data-testid={dataTestId} {...props}>
    {children}
  </Container>
);

const defaultProps: Record<string, Props> = {
  h1: {
    $size: 'l' as SystemSize,
    as: 'h1' as $HtmlElementTag,
    $variant: 'primary' as Variant,
  },
  h2: {
    $size: 'm' as SystemSize,
    as: 'h2' as $HtmlElementTag,
    $variant: 'primary' as Variant,
  },
  h3: {
    $size: 'base' as SystemSize,
    as: 'h3' as $HtmlElementTag,
    $variant: 'primary' as Variant,
  },
  h4: {
    $size: 's' as SystemSize,
    as: 'h4' as $HtmlElementTag,
    $variant: 'primary' as Variant,
  },
  h5: {
    $size: 'xs' as SystemSize,
    as: 'h5' as $HtmlElementTag,
    $variant: 'primary' as Variant,
  },
  body: {
    $size: 's' as SystemSize,
    as: 'p' as $HtmlElementTag,
    $variant: 'primary' as Variant,
  },
  span: {
    $size: 'base' as SystemSize,
    as: 'span' as $HtmlElementTag,
    $variant: 'primary' as Variant,
  },
  label: {
    $size: 'base' as SystemSize,
    as: 'label' as $HtmlElementTag,
    $variant: 'primary' as Variant,
  },
  message: {
    $size: 's' as SystemSize,
    as: 'p' as $HtmlElementTag,
    $withoutMargin: true,
    $variant: 'primary' as Variant,
  },
  byline: {
    $size: 'xs' as SystemSize,
    as: 'p' as $HtmlElementTag,
    $withoutMargin: true,
    $variant: 'primary' as Variant,
  },
};

const weightMap: { [key: string]: 'semiBold' | 'regular' } = {
  h1: 'semiBold',
  h2: 'semiBold',
  h3: 'semiBold',
  h4: 'regular',
  h5: 'regular',
  body: 'regular',
  span: 'regular',
  label: 'semiBold',
};

const isHeading = (as: string): boolean => as.startsWith('h');

const Container = styled.p<Props>(
  ({
    theme,
    $size = 'base',
    as = 'p',
    $withoutMargin = false,
    $variant = 'primary',
    $error = false,
  }) => css`
    font-family: ${theme.getSystem().type.fontFamily.sans};
    font-size: ${theme.fs($size)};
    font-weight: ${theme.fw(weightMap[as] || 'regular')};
    color: ${$error
      ? theme.color('error')
      : $variant === 'primary'
        ? theme.color('primary')
        : theme.color('secondary')};

    line-height: ${theme.lh(isHeading(as) ? 'heading' : 'body')};
    margin: ${$withoutMargin ? '0 0 0 0' : '0 0 0.5em 0'};

    transition: color 0.3s ease-out;

    a,
    a:visited {
      color: currentColor;
    }
  `,
);

// Shorthands
export const Heading1: React.FC<
  Props<React.HTMLAttributes<HTMLHeadingElement>>
> = props => <Typography {...{ ...defaultProps.h1, ...props }} />;

export const Heading2: React.FC<
  Props<React.HTMLAttributes<HTMLHeadingElement>>
> = props => <Typography {...{ ...defaultProps.h2, ...props }} />;

export const Heading3: React.FC<
  Props<React.HTMLAttributes<HTMLHeadingElement>>
> = props => <Typography {...{ ...defaultProps.h3, ...props }} />;

export const Heading4: React.FC<
  Props<React.HTMLAttributes<HTMLHeadingElement>>
> = props => <Typography {...{ ...defaultProps.h4, ...props }} />;

export const Heading5: React.FC<
  Props<React.HTMLAttributes<HTMLHeadingElement>>
> = props => <Typography {...{ ...defaultProps.h5, ...props }} />;

export const Body: React.FC<
  Props<React.HTMLAttributes<HTMLBodyElement>>
> = props => <Typography {...{ ...defaultProps.body, ...props }} />;

export const Span: React.FC<
  Props<React.HTMLAttributes<HTMLSpanElement>>
> = props => <Typography {...{ ...defaultProps.span, ...props }} />;

export const Label: React.FC<
  Props<React.LabelHTMLAttributes<HTMLLabelElement>>
> = props => <Typography {...{ ...defaultProps.label, ...props }} />;

export const Message: React.FC<
  Props<React.HTMLAttributes<HTMLParagraphElement>>
> = props => <Typography {...{ ...defaultProps.message, ...props }} />;

export const Byline: React.FC<
  Props<React.HTMLAttributes<HTMLParagraphElement>>
> = props => <Typography {...{ ...defaultProps.byline, ...props }} />;

export default Typography;
